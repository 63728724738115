'use client';

import { useMemo, useState } from 'react';
import { ErrorEvent, PlayerConfig, PlayerEvent, SourceConfig } from 'bitmovin-player';
import { BitmovinPlayer } from 'bitmovin-player-react';
import PlayerLoader from '@/components/-ui/video-player/player-loader';
import { useGetEnvironmentVariable } from '@/hooks/useGetEnvironmentVariable';
import { useAppSelector } from '@/state/hooks';
import { StreamError } from '@/types/event';
import { cn } from '@/utils/cn';
import { customUi } from './player-ui';
import { useDrmConfig } from './use-drm-config';
import './player-ui.scss';
interface Props {
  type: 'event' | 'video' | 'preview';
  sku?: string;
  hls?: string;
  dash?: string;
  poster?: string;
  showLoader?: boolean;
  previewCredentials?: {
    id: string;
    accessCode: string;
  };
  onError?: (error: StreamError) => void;
  title?: string;
  trackingTitle?: string;
}
const VideoPlayer = ({
  type,
  sku,
  hls,
  dash,
  poster,
  showLoader = false,
  previewCredentials,
  onError,
  title,
  trackingTitle
}: Props) => {
  const {
    BITMOVIN_KEY,
    BITMOVIN_ANALYTICS_KEY,
    CHROMECAST_APP_ID
  } = useGetEnvironmentVariable(['BITMOVIN_KEY', 'BITMOVIN_ANALYTICS_KEY', 'CHROMECAST_APP_ID']);
  const drmConfig = useDrmConfig({
    sku,
    previewCredentials
  });
  const userId = useAppSelector(state => state.auth.token?.customerInfo?.id);
  const [contentLoaded, setContentLoaded] = useState(false);
  const playerConfig: PlayerConfig = useMemo(() => ({
    key: process.env.NEXT_PUBLIC_BITMOVIN_KEY ?? '',
    playback: {
      autoplay: true
    },
    analytics: {
      userId: userId ?? 'Unknown user',
      title: trackingTitle
    },
    remotecontrol: {
      type: 'googlecast',
      receiverApplicationId: process.env.NEXT_PUBLIC_CHROMECAST_APP_ID,
      receiverVersion: 'v3'
    },
    events: {
      [PlayerEvent.Ready]: () => setContentLoaded(true),
      [PlayerEvent.Error]: event => onError ? onError(event as ErrorEvent) : setContentLoaded(true)
    },
    logs: {
      bitmovin: false
    }
  }), [userId, trackingTitle, BITMOVIN_KEY, BITMOVIN_ANALYTICS_KEY, CHROMECAST_APP_ID]);
  const playerSource: SourceConfig = useMemo(() => ({
    hls,
    dash,
    poster,
    title,
    drm: drmConfig
  }), [hls, dash, poster, title, drmConfig]);
  const playerHidden = showLoader || !contentLoaded;
  return <>
      {playerHidden && type === 'event' && <PlayerLoader />}
      <BitmovinPlayer className={cn('h-100', {
      hidden: playerHidden
    })} config={playerConfig} source={playerSource} customUi={customUi} data-sentry-element="BitmovinPlayer" data-sentry-source-file="index.tsx" />
    </>;
};
export default VideoPlayer;